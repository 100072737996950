@font-face {
    font-family: "Resistenza";
    src: url("./fonts/Resistenza-PeperoncinoSans.otf") format('opentype');
}

@font-face {
    font-family: "JenWagner";
    src: url("./fonts/JenWagnerCo-PalmerLakePrintRegular2.otf") format('opentype');
}

.exampleFont {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}