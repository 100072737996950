div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transform-origin: top left;

    &.topLeft {
        transform: scale(0.4);
        top: 3vh;
        left: 3vh;
        right: unset;
        bottom: unset;
    }

    .logo {
        height: 35vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #FFF100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background: #000000;
    background-image: url('../images/Host/BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 0;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Resistenza";
                text-shadow: 0.25vh 0.5vh black;
                font-weight: bold;
                color: #00B497;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Resistenza";
                    text-shadow: 0.25vh 0.5vh black;
                    font-size: 4vh;
                    font-weight: normal;
                    color: #ECEF66;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: 25vh;
        height: 12.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 0.5vh #00B497;
        background-color: #000000;
        border-top-left-radius: 3vh;
        border-bottom-left-radius: 3vh;
        margin: auto;
        right: -3vh;
        top: 21vh;
        padding: 1vh;
        transition: 1s transform;
        transform: translateX(50vh);
        z-index: 11;

        &.show {
            transform: translateX(0);
        }

        .timerText {
            text-align: center;
            font-size: 9vh;
            font-family: "Resistenza";
            color: white;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            left: 0;
            right: 40%;
            bottom: 0;
            margin: auto;
            top: -5%;
        }

        .clock {
            height: 17vh;
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(20%, -8%);
            margin: auto;
        }
    }


    .mainButton {
        font-size: 10vh;
        font-family: "Resistenza";
        padding-bottom: 4vh;
        padding-top: 3vh;
        padding-left: 5vh;
        padding-right: 5vh;
        margin: 1vh;
        line-height: 1;
        background-image: url('../images/Host/Button BG_1.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: unset;
        color: white;
        border: none;

        &.smaller {
            background-image: url('../images/Host/Button BG_2.png');
            font-size: 6vh;
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #00B497;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #01806b !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: 'Resistenza';
                color: #F7EE69;
                margin: auto;
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 2s;


        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }
        }
    }

    .roundFolder {
        position: absolute;
        width: 100vh;
        height: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transform: translateY(125vh) rotate(45deg);
        transition: 2s transform ease-in-out;

        &.show {
            transform: translateY(5vh) rotate(0deg);
        }

        &.zoom {
            transform: translateY(5vh) rotate(0deg) scale(1.15);
        }

        .postItNote {
            position: absolute;
            width: 40%;
            height: fit-content;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            transition: opacity 1s, transform 1s;
            opacity: 0;
            transform: scale(1.5);

            &.show {
                opacity: 1;
                transform: scale(1);
            }

            .postItImg {
                position: relative;
                height: auto;
                width: 100%;
            }

            .postItText {
                position: absolute;
                width: 75%;
                height: fit-content;
                text-align: center;
                margin: auto;
                left: 5%;
                right: 0;
                bottom: 10%;
                top: 0;
                font-family: "JenWagner";
                font-size: 9vh;
                color: black;
                transform: rotate(5deg);
                line-height: 0.6;
                overflow-wrap: break-word;
            }
        }

        .folderSegment {
            position: absolute;
            width: 100%;
            height: fit-content;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transition: 2s transform ease-in;

            &.drop {
                transform: translateY(125vh) rotate(45deg) !important;
            }

            &.rotate1 {
                transform: rotate(3deg);
            }

            &.rotate2 {
                transform: rotate(1deg);
            }

            &.rotate3 {
                transform: rotate(-3deg);
            }

            &.rotate4 {
                transform: rotate(-1deg);
            }

            &.rotate5 {
                transform: rotate(2deg);
            }

            .sheet {
                position: relative;
                width: 100%;
                height: auto;
            }

            .winnerTitle {
                position: absolute;
                width: fit-content;
                height: fit-content;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                text-align: center;
                color: #0085AD;
                font-size: 20vh;
                text-align: center;
                font-family: "Resistenza";
                transform: rotate(-25deg);
                text-shadow: -0.5vh -0.5vh 0 #000, 0.5vh -0.5vh 0 #000, -0.5vh 0.5vh 0 #000, 0.5vh 0.5vh 0 #000;
            }

            .cardContent {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &.white {
                    transform: rotate(-2deg);
                    padding-top: 5%;
                    padding-bottom: 5%;
                    padding-left: 6%;
                    padding-right: 6%;
                    top: 2%;
                }

                &.green {
                    transform: rotate(2deg);
                    padding-top: 8%;
                    padding-bottom: 6%;
                    padding-left: 3.5%;
                    padding-right: 3.5%;
                    top: 2%;
                }

                .questionCard {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;

                    .questionTitle {
                        position: relative;
                        height: fit-content;
                        width: fit-content;
                        color: #0085AD;
                        font-size: 6vh;
                        text-align: center;
                        font-family: "Resistenza";
                    }

                    .questionTable {
                        position: relative;
                        height: 80%;
                        width: 95%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        .tableItem {
                            position: relative;
                            width: 25%;
                            height: 25%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0.5%;

                            &:nth-of-type(1),
                            &:nth-of-type(3),
                            &:nth-of-type(6),
                            &:nth-of-type(8),
                            &:nth-of-type(9),
                            &:nth-of-type(11),
                            &:nth-of-type(14),
                            &:nth-of-type(16) {
                                background: #D2F0E8;
                            }

                            &:nth-of-type(2)
                            &:nth-of-type(4),
                            &:nth-of-type(5),
                            &:nth-of-type(7),
                            &:nth-of-type(10),
                            &:nth-of-type(12),
                            &:nth-of-type(13),
                            &:nth-of-type(15) {
                                background: white;
                            }

                            .itemText {
                                text-align: center;
                                font-family: "Resistenza";
                                font-size: 2.5vh;
                                color: black;
                                overflow-wrap: break-word;
                            }

                            .wordStamp {
                                position: absolute;
                                width: 110%;
                                height: auto;
                                margin: auto;
                                top: 0;
                                bottom: 0;
                                z-index: 1;
                            }

                            .wordCircle {
                                position: absolute;
                                width: 95%;
                                height: auto;
                                margin: auto;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                    }
                }

                .suspectCard {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;

                    .suspectTitle {
                        position: relative;
                        height: fit-content;
                        width: fit-content;
                        color: black;
                        font-size: 6vh;
                        text-align: center;
                        font-family: "Resistenza";
                    }

                    .suspectSection {
                        position: relative;
                        height: 80%;
                        width: 95%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;


                        .suspect {
                            position: relative;
                            width: 43%;
                            height: 22%;
                            margin-left: auto;
                            margin-top: 1.5%;

                            &:nth-last-child(n+1):first-child,
                            &:nth-last-child(n+1):first-child ~ .suspect {
                                margin-right: auto;
                            }

                            &:nth-last-child(n+5):first-child,
                            &:nth-last-child(n+5):first-child ~ .suspect {
                                margin-right: unset;
                            }

                            &:nth-child(1) {
                                .mugshot {
                                    transform: rotate(5deg);
                                }
                            }

                            &:nth-child(2) {
                                .mugshot {
                                    transform: rotate(-3deg);
                                }
                            }

                            &:nth-child(3) {
                                .mugshot {
                                    transform: rotate(-1deg);
                                }
                            }

                            &:nth-child(4) {
                                .mugshot {
                                    transform: rotate(2deg);
                                }
                            }

                            &:nth-child(5) {
                                .mugshot {
                                    transform: rotate(-4deg);
                                }
                            }

                            &:nth-child(6) {
                                .mugshot {
                                    transform: rotate(1deg);
                                }
                            }

                            &:nth-child(7) {
                                .mugshot {
                                    transform: rotate(4deg);
                                }
                            }

                            &:nth-child(8) {
                                .mugshot {
                                    transform: rotate(-5deg);
                                }
                            }

                            .suspectBg {
                                position: relative;
                                width: 100%;
                                height: auto;
                            }

                            .mugshot {
                                position: absolute;
                                width: 30%;
                                margin: auto;
                                left: -12%;
                            }

                            .votedStamp {
                                position: absolute;
                                width: 30%;
                                margin: auto;
                                left: -12%;
                                top: -12%;
                                z-index: 1;
                                transition: opacity 1s, transform 1s;
                                opacity: 0;
                                transform: scale(1.25);

                                &.show {
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }

                            .suspectContent {
                                position: absolute;
                                height: 100%;
                                width: 78%;
                                display: flex;
                                flex-direction: column;
                                margin: auto;
                                left: 20%;
                                top: 0;
                                bottom: 0;

                                .nameBox {
                                    position: relative;
                                    width: 100%;
                                    height: 25%;
                                    display: flex;
                                    justify-content: start;
                                    align-items: center;

                                    .name {
                                        position: relative;
                                        height: fit-content;
                                        width: fit-content;
                                        color: black;
                                        font-size: 1.75vh;
                                        text-align: center;
                                        font-family: "Resistenza";

                                        .written {
                                            font-family: "JenWagner";
                                            font-size: 4.5vh;
                                            color: #007DA5;
                                        }
                                    }
                                }

                                .largeBox {
                                    position: relative;
                                    width: 100%;
                                    height: 70%;
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 4%;

                                    .smallTitle {
                                        position: relative;
                                        height: fit-content;
                                        width: fit-content;
                                        color: black;
                                        font-size: 1.75vh;
                                        text-align: center;
                                        font-family: "Resistenza";
                                    }

                                    .clue {
                                        position: relative;
                                        height: fit-content;
                                        width: fit-content;
                                        font-size: 5vh;
                                        text-align: center;
                                        color: #007DA5;
                                        font-family: "JenWagner";
                                        line-height: 0.5;
                                        bottom: 16%;
                                        overflow-wrap: break-word;
                                        align-self: center;

                                        &.larger {
                                            font-size: 8vh;
                                            bottom: 30%;
                                        }
                                    }

                                    .voteList {
                                        position: relative;
                                        height: fit-content;
                                        width: fit-content;
                                        font-size: 10vh;
                                        text-align: center;
                                        color: #007DA5;
                                        font-family: "JenWagner";
                                        line-height: 0.5;
                                        bottom: 25%;
                                    }
                                }
                            }
                        }
                    }
                }

                .guessCard {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: end;
                    align-items: center;

                    .potato {
                        position: relative;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 35vh;
                        width: auto;
                    }

                    .name {
                        width: fit-content;
                        height: fit-content;
                        text-align: center;
                        font-family: "Resistenza";
                        font-size: 10vh;
                        color: #ECEF66;
                        line-height: 1;
                    }

                    .subText {
                        width: fit-content;
                        height: fit-content;
                        font-family: "Resistenza";
                        text-align: center;
                        font-size: 6vh;
                        color: white;
                        line-height: 1;
                        margin-bottom: 4%;
                    }
                }
            }
        }

        .roundNumber {
            position: absolute;
            width: 75%;
            height: auto;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }

    .gameOverSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;

        &.show {

            .winnerHalf {
                .winnerBoxes {
                    clip-path: circle(100% at 50% 50%);
                }
            }
        }

        .winnerHalf {
            position: relative;
            width: 60%;
            height: 100%;

            .winnerBoxes {
                position: absolute;
                height: 50%;
                width: 80vh;
                margin: auto;
                left: 10vh;
                bottom: 15vh;
                display: flex;
                transition: linear 1s clip-path;
                clip-path: circle(0% at 50% 50%);

                .spotlight {
                    position: absolute;
                    width: 80%;
                    aspect-ratio: 1/1;
                    background-color: #EDEF66;
                    border-radius: 50%;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 20vh;
                    top: 0;
                }

                .podium {
                    position: absolute;
                    margin: auto;
                    bottom: 0;
                    display: flex;
                    flex-direction: column-reverse;
                    width: 100%;
                    top: 0;

                    .boxes {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        margin: auto;
                        z-index: 1;
                    }

                    .potato {
                        position: absolute;
                        width: 40%;
                        height: auto;
                        margin: auto;
                        bottom: 20vh;

                        &.pos1 {
                            bottom: 48vh;
                        }

                        &.pos2 {
                            bottom: 33vh;
                        }

                        &.pos3 {
                            bottom: 18vh;
                            z-index: 2;
                        }
                    }

                    &.right {
                        right: 0;
                        z-index: 1;

                        .potato {
                            right: 3vh;
                        }
                    }

                    &.left {
                        left: 0;
                        z-index: 2;

                        .potato {
                            left: 3vh;
                        }
                    }

                    &.middle {
                        left: 0;
                        right: 0;
                        z-index: 3;

                        .potato {
                            right: 0;
                            left: 0;
                        }
                    }
                }
            }
        }

        .leaderboardSection {
            position: relative;
            width: 40%;
            height: fit-content;
            margin: auto;
            justify-content: start;
            align-items: start;
            display: flex;
            flex-direction: column;
            transition: 0.75s transform;
            transform: translateX(50vw);
            top: 5%;
            right: 5%;

            &.show {
                transform: translateX(0vw);
            }

            .tableRow {
                position: relative;
                font-family: "Resistenza";
                font-size: 4vh;
                display: flex;
                flex-direction: row;
                width: 90%;
                align-items: center;
                padding-right: 10%;
                /*padding-left: 2%;*/

                .bgImg {
                    position: absolute;
                    width: 95%;
                    height: 100%;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: -1;
                    object-fit: cover;
                }
            }

            .player {
                border-radius: 1.5vh;
                margin-top: 3.5%;


                div {
                    font-family: "Resistenza";
                    font-size: 4vh;
                    border: none;
                    color: white;

                    .smaller {
                        font-size: 3vh;
                        align-self: end;
                        margin-bottom: 0.25vh;
                        margin-left: 0.25vh;
                    }
                }
            }

            .position {
                position: relative;
                text-align: start;
                width: 5%;
                text-align: start;
                color: #EDEF66 !important;
                transform: scale(1.5);
            }

            .name {
                width: 75%;
                text-align: start;
                padding-left: 7.5%;
            }

            .score {
                width: 20%;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .potato {
                position: absolute;
                width: auto;
                height: 9.5vh;
                margin: auto;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
    }


    .playAgainButtonSection {
        width: 55%;
        height: 20%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: 4.5vh;
            font-family: "Resistenza";
            padding-bottom: 3vh;
            padding-top: 2vh;
            padding-left: 5vh;
            padding-right: 5vh;
            margin: 1vh;
            line-height: 1;
            /*            background-image: url('../images/Host/ButtonImg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;*/
            background-color: transparent;
            color: white;
            border: none;
            text-transform: uppercase;
            z-index: 1;

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}


