div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Resistenza";
        color: #F7EE69;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        white-space: pre-wrap;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 5vh;
        font-family: "Resistenza";
        color: #F7EE69;
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {
        .playerRow {
            display: flex;
            width: auto;
            height: auto;
            justify-content: center;
            align-items: center;

            .player {
                width: 12.5vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;

                &.show {
                    transform: scale(1);
                }

                .chameleon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(1.15);
                }
            }
        }
    }

    .stepTwo {
        .playerCol {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 40%;
            gap: 15%;
            bottom: 0;
            justify-content: center;
            transition: 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

            &.left {
                left: 0;

                &.move {
                    left: 25%;
                }
            }

            &.right {
                right: 0;

                &.move {
                    right: 25%;
                }
            }

            .player {
                width: 12.5vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;
                gap: 5vh;

                &.show {
                    transform: scale(1);
                }

                .chameleon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scale(1.15);
                }

                .answer {
                    width: 80%;
                    height: auto;
                    position: absolute;
                    top: -20%;
                    /*bottom: 0;*/
                    transform: scale(1.1);
                }

                .vote {
                    width: 80%;
                    height: auto;
                    position: absolute;
                    bottom: 15%;
                    transform: scale(1.1);

                    &.one {
                        transform: rotate(0);
                        right: -65%;
                    }

                    &.two {
                        transform: rotate(-35deg);
                        right: -65%;
                        bottom: 20%;
                    }

                    &.three {
                        transform: rotate(-90deg);
                        left: 0;
                        right: 0;
                        top: -25%;
                        bottom: unset !important;
                    }
                }

                &.left {
                    .answer {
                        right: -65%;
                    }
                }

                &.right {
                    .answer {
                        left: -65%;
                    }
                }
            }
        }

        .roundFolder {
            width: 80vh;
            height: fit-content;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            transform: translateY(125vh) rotate(45deg);
            transition: 2s transform ease-in-out;

            &.show {
                transform: translateY(0vh) rotate(0deg);
            }


            .folderSegment {
                width: 100%;
                height: fit-content;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transition: 2s transform ease-in;

                &.drop {
                    transform: translateY(125vh) rotate(45deg) !important;
                }

                &.rotate1 {
                    transform: rotate(3deg);
                }

                &.rotate2 {
                    transform: rotate(1deg);
                }

                &.rotate3 {
                    transform: rotate(-3deg);
                }

                &.rotate4 {
                    transform: rotate(-1deg);
                }

                &.rotate5 {
                    transform: rotate(2deg);
                }

                .sheet {
                    position: relative;
                    width: 100%;
                    height: auto;
                }

                .cardContent {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    &.white {
                        transform: rotate(-2deg);
                        padding-top: 5%;
                        padding-bottom: 5%;
                        padding-left: 6%;
                        padding-right: 6%;
                        top: 2%;
                    }

                    &.green {
                        transform: rotate(2deg);
                        padding-top: 8%;
                        padding-bottom: 6%;
                        padding-left: 3.5%;
                        padding-right: 3.5%;
                        top: 2%;
                    }

                    .questionCard {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;

                        .questionTitle {
                            position: relative;
                            height: fit-content;
                            width: fit-content;
                            color: #0085AD;
                            font-size: 5vh;
                            text-align: center;
                            font-family: "Resistenza";
                        }

                        .questionTable {
                            position: relative;
                            height: 80%;
                            width: 95%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;

                            .tableItem {
                                position: relative;
                                width: 25%;
                                height: 25%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0.5%;

                                &:nth-of-type(1),
                                &:nth-of-type(3),
                                &:nth-of-type(6),
                                &:nth-of-type(8),
                                &:nth-of-type(9),
                                &:nth-of-type(11),
                                &:nth-of-type(14),
                                &:nth-of-type(16) {
                                    background: #D2F0E8;
                                }

                                &:nth-of-type(2)
                                &:nth-of-type(4),
                                &:nth-of-type(5),
                                &:nth-of-type(7),
                                &:nth-of-type(10),
                                &:nth-of-type(12),
                                &:nth-of-type(13),
                                &:nth-of-type(15) {
                                    background: white;
                                }

                                .itemText {
                                    text-align: center;
                                    font-family: "Resistenza";
                                    font-size: 2.45vh;
                                    color: black;
                                    overflow-wrap: break-word;
                                }

                                .wordStamp {
                                    position: absolute;
                                    width: 110%;
                                    height: auto;
                                    margin: auto;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                }

                                .wordCircle {
                                    position: absolute;
                                    width: 95%;
                                    height: auto;
                                    margin: auto;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .roundNumber {
                position: absolute;
                width: 75%;
                height: auto;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    .stepThree {
        .rowBox {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            align-items: center;
            gap: 10vh;


            .roundFolder {
                width: 80vh;
                height: fit-content;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                transform: translateY(125vh) rotate(45deg);
                transition: 2s transform ease-in-out;

                &.show {
                    transform: translateY(0vh) rotate(0deg);
                }


                .folderSegment {
                    width: 100%;
                    height: fit-content;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    transition: 2s transform ease-in;

                    &.drop {
                        transform: translateY(125vh) rotate(45deg) !important;
                    }

                    &.rotate1 {
                        transform: rotate(3deg);
                    }

                    &.rotate2 {
                        transform: rotate(1deg);
                    }

                    &.rotate3 {
                        transform: rotate(-3deg);
                    }

                    &.rotate4 {
                        transform: rotate(-1deg);
                    }

                    &.rotate5 {
                        transform: rotate(2deg);
                    }

                    .sheet {
                        position: relative;
                        width: 100%;
                        height: auto;
                    }

                    .cardContent {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;

                        &.white {
                            transform: rotate(-2deg);
                            padding-top: 5%;
                            padding-bottom: 5%;
                            padding-left: 6%;
                            padding-right: 6%;
                            top: 2%;
                        }

                        &.green {
                            transform: rotate(2deg);
                            padding-top: 8%;
                            padding-bottom: 6%;
                            padding-left: 3.5%;
                            padding-right: 3.5%;
                            top: 2%;
                        }

                        .questionCard {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            justify-content: center;
                            align-items: center;

                            .questionTitle {
                                position: relative;
                                height: fit-content;
                                width: fit-content;
                                color: #0085AD;
                                font-size: 5vh;
                                text-align: center;
                                font-family: "Resistenza";
                            }

                            .questionTable {
                                position: relative;
                                height: 80%;
                                width: 95%;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;

                                .tableItem {
                                    position: relative;
                                    width: 25%;
                                    height: 25%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0.5%;

                                    &:nth-of-type(1),
                                    &:nth-of-type(3),
                                    &:nth-of-type(6),
                                    &:nth-of-type(8),
                                    &:nth-of-type(9),
                                    &:nth-of-type(11),
                                    &:nth-of-type(14),
                                    &:nth-of-type(16) {
                                        background: #D2F0E8;
                                    }

                                    &:nth-of-type(2)
                                    &:nth-of-type(4),
                                    &:nth-of-type(5),
                                    &:nth-of-type(7),
                                    &:nth-of-type(10),
                                    &:nth-of-type(12),
                                    &:nth-of-type(13),
                                    &:nth-of-type(15) {
                                        background: white;
                                    }

                                    .itemText {
                                        text-align: center;
                                        font-family: "Resistenza";
                                        font-size: 2.45vh;
                                        color: black;
                                        overflow-wrap: break-word;
                                    }

                                    .wordStamp {
                                        position: absolute;
                                        width: 110%;
                                        height: auto;
                                        margin: auto;
                                        top: 0;
                                        bottom: 0;
                                        z-index: 1;
                                    }

                                    .wordCircle {
                                        position: absolute;
                                        width: 95%;
                                        height: auto;
                                        margin: auto;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .roundNumber {
                    position: absolute;
                    width: 75%;
                    height: auto;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }

            .chameleon {
                width: 10vw;
                height: auto;
                transform: scale(1.15);
                position: relative;

                .guess {
                    width: 80%;
                    height: auto;
                    position: absolute;
                    top: -34%;
                    right: -55%;
                    transform: scale(1.1);
                }
            }
        }
    }

    .stepFour {
    }

    .stepFive {
    }

    .stepSix {
    }
}
