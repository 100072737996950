[contenteditable] {
    outline: 0px solid transparent;
}


:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 600px !important;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: #85DCCB;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-image: url('../images/Mobile BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    overflow: hidden;

    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: "Resistenza";
            text-align: center;
        }

        .refreshButton {
            font-family: 'Lucida Sans Unicode';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: white;
            border-radius: 20px;
            background-color: #007DA5;
            box-shadow: 0px 6px 0px 0px #014656;
            cursor: pointer;
        }
    }

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            font-family: "Resistenza";
            color: white;
        }
    }

    .spectatingBox {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        z-index: 1000;

        .spectatingText {
            margin: auto;
            margin-bottom: unset;
            width: fit-content;
            height: fit-content;
            text-align: center;
            font-size: 8vh;
            font-family: "Resistenza";
            color: white;
        }

        .subText {
            margin: auto;
            margin-top: unset;
            width: fit-content;
            height: fit-content;
            text-align: center;
            font-size: 5vh;
            font-family: "Resistenza";
            color: white;
        }
    }

    .skipContainer {
        background-color: #00B497;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 4vh;
            font-family: "Resistenza";
            color: white;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
        }
    }

    .voteTopicsSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .voteTitle {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 5%;
            margin: auto;
            text-align: center;
            font-size: 8vh;
            color: black;
        }

        .voteTopicsBox {
            width: 75%;
            height: 60%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin: auto;

            .voteTopic {
                display: flex;
                width: 100%;
                height: 40%;
                margin: auto;
                background-color: #FFFB85;

                &.selected {
                    transform: scale(1.2);
                }

                .topicText {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    padding: 5%;
                    margin: auto;
                    text-align: center;
                    font-size: 5vh;
                    color: #41C68F;
                }
            }
        }
    }

    .playerStatusSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .playerName {
            width: fit-content;
            height: fit-content;
            margin: auto;
            text-align: center;
            font-size: 6vh;
            color: #00B594;
        }

        .textBox {
            margin: auto;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
        }

        .subText {
            width: fit-content;
            height: fit-content;
            margin: auto;
            text-align: center;
            font-size: 6vh;
            color: #00B594;
        }

        .boldText {
            width: fit-content;
            height: fit-content;
            margin: auto;
            text-align: center;
            font-size: 7vh;
            color: #FFFB85;
        }
    }

    .clueSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .secretBox {
            background-image: url('../images/Secret Word Card.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            padding-left: 5%;
            padding-right: 5%;
            padding-top: 10%;
            padding-bottom: 10%;
            width: 85%;
            max-height: 40%;
            max-width: 85%;

            &.chameleon {
                background-image: url('../images/Chameleon Card.png');
            }

            .secretTitle {
                margin: auto;
                font-family: "Resistenza";
                font-size: 4vh;
                color: #007DA5;
                line-height: 1;
            }

            .secretWord {
                margin: auto;
                font-family: "Resistenza";
                font-size: 9vh;
                color: black;
                line-height: 1;
                text-align: center;
                position: relative;

                .cornerChameleon {
                    position: absolute;
                    height: 8vh;
                    margin: auto;
                    right: -20%;
                    top: -86%;
                }

                &.smaller {
                    font-size: 6vh;
                }

                &.chameleon {
                    color: #ECEF66;
                    font-size: 6vh;

                    &.smaller {
                        font-size: 5vh;
                        color: white;
                    }
                }
            }
        }

        .title {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-bottom: unset;
            text-align: center;
            font-size: 6vh;
            color: #ECEF66;
            font-family: "Resistenza";
        }

        .inputSection {
            margin: auto;
            margin-bottom: 5%;
            margin-top: unset;
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;

            .clueInputBox {
                width: 75%;
                height: fit-content;
                font-family: "Resistenza";
                text-align: center;
                margin: auto;
                margin-top: 1vh;
                border: #007DA5 0.3vh solid !important;
                /*border-radius: 2vh;*/
                font-size: 3.5vh;
                color: white;
                background-color: black !important;
                display: flex;
                padding-top: 2%;
                padding-bottom: 2%;
                /*box-shadow: inset 5px 5px 20px -4px rgba(0,0,0,0.35);*/
                /* background-image: url('../images/Fill Space.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;*/

                &::placeholder {
                    color: rgba(128, 128, 128, 0.5);
                    font-size: 3.5vh;
                }
            }
        }

        .hintText {
            width: 80%;
            height: fit-content;
            margin: auto;
            margin-bottom: unset;
            text-align: center;
            font-size: 3.5vh;
            font-family: "Resistenza";
            color: #00B497;
        }

        .chameleonImage {
            width: 30%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: unset;
        }
    }

    .playerVoteSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .title {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-top: 0%;
            margin-bottom: 0%;
            text-align: center;
            font-size: 5vh;
            color: #ECEF66;
            font-family: "Resistenza";

            &.smaller {
                font-size: 3.5vh;
            }

            &.blue {
                font-size: 3vh;
                color: #007DA5;
                margin-top: 1%;
                margin-bottom: 1%;
            }
        }

        .playerList {
            width: 80%;
            max-width: 500px;
            max-height: 60%;
            /*height: 60%;*/
            margin: auto;
            margin-top: unset;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .playerVoteOption {
                width: fit-content;
                height: fit-content;
                /*max-height: 33%;*/
                width: 31%;
                margin: auto;
                display: flex;
                border-radius: 1vh;
                margin-top: 1%;
                margin-bottom: 1%;
                margin-left: 1%;
                margin-right: 1%;
                position: relative;
                flex-direction: column;

                &.fade {
                    opacity: 0.5;
                }

                .mugShot {
                    margin: auto;
                    width: 100%;
                    height: fit-content;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    &.selected {
                        background-color: #ECEF66;
                        background-blend-mode: multiply;
                    }
                }

                .playerName {
                    width: fit-content;
                    height: fit-content;
                    text-align: center;
                    font-size: 3vh;
                    margin: auto;
                    color: #ECEF66;
                    font-family: "Resistenza";
                    line-height: 1;
                }

                .playerVote {
                    width: fit-content;
                    height: fit-content;
                    text-align: center;
                    font-size: 2vh;
                    margin: auto;
                    color: white;
                    font-family: "Resistenza";
                    line-height: 1;
                }
            }
        }

        .voteButton {
            margin: auto;
            margin-bottom: unset;
            width: 33%;

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .wordGuessSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .topChameleon {
            margin-left: auto;
            height: 12.5vh;
            width: auto;
            margin-right: 5%;
            margin-top: 5%;
        }

        .title {
            width: 90%;
            height: fit-content;
            margin: auto;
            margin-top: 2%;
            margin-bottom: 3%;
            text-align: center;
            font-size: 7.5vh;
            text-overflow: ellipsis;
            overflow: hidden;
            color: white;
            font-family: "Resistenza";
        }

        .subTitle {
            width: fit-content;
            height: fit-content;
            margin: auto;
            margin-bottom: unset;
            margin-top: 3%;
            text-align: center;
            font-size: 4vh;
            color: #ECEF66;
            font-family: "Resistenza";
        }

        .wordListSection {
            margin: auto;
            margin-bottom: 5%;
            margin-top: unset;
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;

            .wordList {
                width: 75%;
                height: fit-content;
                font-family: "Resistenza";
                text-align: center;
                margin: auto;
                margin-top: 1vh;
                border: #007DA5 0.3vh solid !important;
                /*border-radius: 2vh;*/
                font-size: 3.5vh;
                color: white;
                background-color: black !important;
                display: flex;
                padding-top: 2%;
                padding-bottom: 2%;
                /*box-shadow: inset 5px 5px 20px -4px rgba(0,0,0,0.35);*/
                /* background-image: url('../images/Fill Space.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;*/

                &::placeholder {
                    color: rgba(128, 128, 128, 0.5);
                    font-size: 3.5vh;
                }
            }
        }
        /* .wordList {
            margin: auto;
            margin-top: 5%;
            width: 75%;
            height: fit-content;
            font-size: 2.5vh;
            text-align: center;
        }*/
    }

    .submitButton {
        margin: auto;
        margin-top: unset;
        width: fit-content;
        height: fit-content;
        text-align: center;
        font-size: 5vh;
        background-color: #007DA5;
        color: white;
        font-family: "Resistenza";
        border-radius: 2vh;
        padding-left: 2%;
        padding-right: 2%;
        box-shadow: 0px 1vh 0px 0px #014656;

        &.bottom {
            margin-bottom: 5%;
        }

        &.disabled {
            opacity: 0.5;
        }
    }
}


