

.player {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 0.5vh;
    justify-content: start;
    align-items: center;
    transition: 1s height, 1s transform;
    height: 0%;
    transform: scale(0);

    &.show {
        transform: scale(1);
        height: 22%;
    }

    .spotlight {
        position: absolute;
        width: 12vh;
        height: 12vh;
        border-radius: 50%;
        background: #ECEF66;
        margin: auto;
        top: 1vh;
        left: 0vh;
    }

    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        width: auto;
    }

    .textSection {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 50%;

        .name {
            font-family: "Resistenza";
            text-shadow: 0.2vh 0.2vh black;
            font-size: 2.75vh;
            color: white;
            background-image: url('../images/Host/Potato name bar.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-left: 0.75vh;
            padding-right: 0.75vh;
            min-width: 100%;
            width: fit-content;

            &.large {
                font-size: 3.5vh;
            }

            &.medium {
                font-size: 2.75vh;
            }

            &.small {
                font-size: 2vh;
            }
        }

        .points {
            color: white;
            font-size: 2.5vh;
            font-family: "Resistenza";
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        .spotlight {
            right: 0vh;
            left: unset;
        }

        .textSection {
            .name {
                text-align: end;
            }

            .points {
                text-align: end;
            }
        }
    }
}
